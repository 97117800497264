<template>
  <svg class="fill-current" width="24" height="23" viewBox="0 0 24 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M9.50276 14.542V18.7087C9.50276 19.5375 9.832 20.3324 10.418 20.9184C11.0041 21.5045 11.799 21.8337 12.6278 21.8337L16.7944 12.4587V1.00038H5.04442C4.542 0.994697 4.05445 1.17079 3.67161 1.4962C3.28876 1.82162 3.03643 2.27443 2.96109 2.77121L1.52359 12.1462C1.47827 12.4448 1.49841 12.7497 1.58261 13.0397C1.66682 13.3297 1.81307 13.598 2.01124 13.8259C2.20941 14.0538 2.45476 14.2359 2.73029 14.3595C3.00582 14.4832 3.30494 14.5455 3.60692 14.542H9.50276ZM16.7944 1.00038H19.5757C20.1652 0.98995 20.7381 1.19635 21.1855 1.5804C21.6329 1.96444 21.9237 2.4994 22.0028 3.08371V10.3754C21.9237 10.9597 21.6329 11.4946 21.1855 11.8787C20.7381 12.2627 20.1652 12.4691 19.5757 12.4587H16.7944"
      fill="fillCurrent"/>
    <path
      d="M16.7944 1.00038H19.5757C20.1652 0.98995 20.7381 1.19635 21.1855 1.5804C21.6329 1.96444 21.9237 2.4994 22.0028 3.08371V10.3754C21.9237 10.9597 21.6329 11.4946 21.1855 11.8787C20.7381 12.2627 20.1652 12.4691 19.5757 12.4587H16.7944M9.50276 14.542V18.7087C9.50276 19.5375 9.832 20.3324 10.418 20.9184C11.0041 21.5045 11.799 21.8337 12.6278 21.8337L16.7944 12.4587V1.00038H5.04442C4.542 0.994697 4.05445 1.17079 3.67161 1.4962C3.28876 1.82162 3.03643 2.27443 2.96109 2.77121L1.52359 12.1462C1.47827 12.4448 1.49841 12.7497 1.58261 13.0397C1.66682 13.3297 1.81307 13.598 2.01124 13.8259C2.20941 14.0538 2.45476 14.2359 2.73029 14.3595C3.00582 14.4832 3.30494 14.5455 3.60692 14.542H9.50276Z"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "DislikeIcon"
}
</script>

<style scoped>

</style>
