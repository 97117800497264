<template>
  <svg class="fill-current" width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M13.5 8.29167V4.125C13.5 3.2962 13.1708 2.50134 12.5847 1.91529C11.9987 1.32924 11.2038 1 10.375 1L6.20833 10.375V21.8333H17.9583C18.4608 21.839 18.9483 21.6629 19.3312 21.3375C19.714 21.0121 19.9663 20.5593 20.0417 20.0625L21.4792 10.6875C21.5245 10.3889 21.5043 10.084 21.4201 9.79401C21.3359 9.50398 21.1897 9.23572 20.9915 9.00783C20.7933 8.77993 20.548 8.59784 20.2725 8.47418C19.9969 8.35052 19.6978 8.28825 19.3958 8.29167H13.5ZM6.20833 21.8333H3.08333C2.5308 21.8333 2.00089 21.6138 1.61019 21.2231C1.21949 20.8324 1 20.3025 1 19.75V12.4583C1 11.9058 1.21949 11.3759 1.61019 10.9852C2.00089 10.5945 2.5308 10.375 3.08333 10.375H6.20833"
      fill="fillCurrent"/>
    <path
      d="M6.20833 10.375L10.375 1C11.2038 1 11.9987 1.32924 12.5847 1.91529C13.1708 2.50134 13.5 3.2962 13.5 4.125V8.29166H19.3958C19.6978 8.28825 19.9969 8.35052 20.2725 8.47418C20.548 8.59784 20.7933 8.77993 20.9915 9.00783C21.1897 9.23572 21.3359 9.50398 21.4201 9.79401C21.5043 10.084 21.5245 10.3889 21.4792 10.6875L20.0417 20.0625C19.9663 20.5593 19.714 21.0121 19.3312 21.3375C18.9483 21.6629 18.4608 21.839 17.9583 21.8333H6.20833M6.20833 10.375V21.8333M6.20833 10.375H3.08333C2.5308 10.375 2.00089 10.5945 1.61019 10.9852C1.21949 11.3759 1 11.9058 1 12.4583V19.75C1 20.3025 1.21949 20.8324 1.61019 21.2231C2.00089 21.6138 2.5308 21.8333 3.08333 21.8333H6.20833"
      stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
</template>

<script>
export default {
  name: "LikeIcon"
}
</script>

<style scoped>

</style>
