<template>
  <ion-page>
    <ion-content>
      <PageHeader bg="bg-blue" title="">
        <template slot="button">
          <button @click="back">
            <ArrowLeftIcon />
          </button>
        </template>
      </PageHeader>
      <div class="relative z-10 mt-24">
        <template v-if="working">
          <div class="my-16 flex items-center justify-center">
            <ion-spinner name="dots"></ion-spinner>
          </div>
        </template>

        <template v-else>
          <template v-if="! exercise">
            <div class="my-16 flex items-center justify-center">
              This exercise doesn't exist.
            </div>
          </template>

          <template v-if="exercise">
            <!--Video-->
            <div
              class="video-height flex justify-center items-center absolute inset-x-0 pb-full px-8 bg-green-200 bg-opacity-50 -mt-34">

              <ion-spinner color="light" v-show="!this.playing" class="absolute"
                name="lines"></ion-spinner>
              <video v-if="long_video_url" id="longVideo" ref="longVideo" @click="toggleAudio"
                class="rounded-lg min-w-full min-h-full bg-black w-full h-full object-cover"
                :poster="thumbnail" @play="playing = true"
                @pause="playing = false" muted autoplay webkit-playsinline
                playsinline loop>
                <source :src="long_video_url" type='video/mp4'>
              </video>
            </div>

            <transition name="simple-fade" appear>
              <div v-if="showSoundFeedback"
                class="video-height z-10 sound-feedback absolute inset-x-0 pb-full -mt-34 flex items-center justify-center pointer-events-none">
                <div class=" p-4 rounded-lg bg-black bg-opacity-50 text-white">
                  <SoundOnIcon v-if="! muted" class="w-10 h-10" />
                  <SoundOffIcon v-else class="w-10 h-10" />
                </div>
              </div>
            </transition>

            <div class="px-8">
              <div class="pt-24 hlg:pt-40 mb-5 hlg:mb-12">
                <h2 class="mb-3">{{ exercise.title }}</h2>
                <div class="clamp-5" @click="showPopoverDescription = true"
                  v-html="exercise.body"></div>
              </div>
              <div class="grid grid-cols-2 gap-x-5">
                <button v-if="exercise.vote != -1" @click.prevent="downvote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-white text-lighPurple w-full flex-shrink-0">
                  <DislikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold">Down Vote</span>
                </button>
                <button v-else @click.prevent="clearVote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-lightPurple text-lighPurple w-full flex-shrink-0">
                  <DislikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold text-white">Down Vote</span>
                </button>

                <button v-if="exercise.vote != 1" @click.prevent="upvote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-white text-lighPurple w-full flex-shrink-0">
                  <LikeIcon class="text-lightPurple mr-3" />
                  <span class="text-xs font-bold">Up Vote</span>
                </button>
                <button v-else @click.prevent="clearVote"
                  class="flex items-center justify-center px-4 py-3 rounded-full bg-lightPurple text-lightPurple w-full flex-shrink-0">
                  <LikeIcon class="mr-3" />
                  <span class="text-xs font-bold text-white">Up Vote</span>
                </button>
              </div>
            </div>
          </template>
        </template>
      </div>
      <transition
        enter-active-class="transition ease-out duration-300 transform"
        enter-class="opacity-0 scale-95 translate-y-10"
        enter-to-class="opacity-100 scale-100 translate-y-0"
        leave-active-class="transition ease-in duration-150 transform"
        leave-class="opacity-100 scale-100 translate-y-0"
        leave-to-class="opacity-0 scale-95 translate-y-10">
        <template v-if="showPopoverDescription">
          <div @click="showPopoverDescription = false"
            class="description-overlay absolute z-50 inset-0 bg-black bg-opacity-25 flex items-center justify-center p-6 pb-24 max-h-screen overflow-hidden">
            <div @click.stop class="flex-1 bg-white p-8 rounded-lg">
              <h2 class="mb-6 text-2xl">{{ exercise.title }}</h2>
              <div class="overflow-y-scroll" v-html="exercise.body"
                style="max-height: 60vh"></div>
              <div class="mt-8 flex items-center justify-center">
                <button @click.prevent="showPopoverDescription = false"
                  type="button"
                  class="text-sm text-lightPurple uppercase font-bold tracking-widest">
                  Close
                </button>
              </div>
            </div>
          </div>
        </template>
      </transition>
    </ion-content>
  </ion-page>
</template>

<script>
import { mapActions,mapState } from "vuex";
import {
  Plugins,
  FilesystemDirectory,
  FilesystemEncoding,
} from "@capacitor/core";

const { Filesystem } = Plugins;

import getUrlFilename from "@/utils/getUrlFilename";

import axios from "axios";
import DislikeIcon from "@/components/svg/DislikeIcon";
import LikeIcon from "@/components/svg/LikeIcon";
import ArrowLeftIcon from "@/components/svg/ArrowLeftIcon";
import SoundOnIcon from "@/components/svg/SoundOnIcon";
import SoundOffIcon from "@/components/svg/SoundOffIcon";
import PageHeader from "@/components/shared/PageHeader";
export default {
  name: "MoreInfo",

  components: {
    PageHeader,
    ArrowLeftIcon,
    LikeIcon,
    DislikeIcon,
    SoundOnIcon,
    SoundOffIcon,
  },

  data() {
    return {
      working: false,
      loading: false,
      exercise: null,
      showPopoverDescription: false,
      playing: false,
      muted: true,
      showSoundFeedback: false,
      long_video_url:null
    };
  },
  mounted: async function () {
   // this.exercise = this.library.find(
    //  (exercise) => exercise.id == this.$route.params.id
    //);

    //if (!this.exercise) {
    
    this.fetchExercise();
  },
  computed: {
    ...mapState({
      workout: (state) => state.workout,
    }),

    library() {
      return this.$store.state.library;
    },

    thumbnail: function () {
      return this.exercise.long_video_thumbnail !== null
        ? this.exercise.long_video_thumbnail_url
        : "/images/default-thumb.png";
    },
  },

  watch: {
    muted(muted) {
      this.showSoundFeedback = true;
      this.$refs.longVideo.muted = muted;

      setTimeout(() => {
        this.showSoundFeedback = false;
      }, 750);
    },
  },

  methods: {
    ...mapActions({
      fetchWorkout: "workout/fetchWorkout",
    }),

    back() {
      this.$router.push({ name: "library" });
    },

    fetchExercise: async function() {
      this.working = true;
      
      axios
        .get(`/exercises/${this.$route.params.id}`)
        .then(async(response) => {
          this.exercise = response.data.data;
          try {
           let contents = await Filesystem.readFile({
                path: `long_videos/${getUrlFilename(this.exercise.long_video_url)}`,
                directory: FilesystemDirectory.External,
                encoding: FilesystemEncoding.UTF8,
                recursive: true
            });
        
            this.long_video_url = contents.data;
          } catch (ex) {
            this.downloadExercise();
            this.long_video_url = this.exercise.long_video_url;
          } 
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => (this.working = false));
    },
  

    toggleLongVideoPlay() {
      const video = this.$refs.longVideo;

      if (video.paused) {
        return video.play();
      }

      video.pause();
    },

    toggleAudio() {
      if (!this.playing) {
        return;
      }

      this.muted = !this.muted;
    },

    upvote() {
      this.$set(this.exercise, "vote", 1);
      axios.post(`/exercises/${this.exercise.id}/upvote`);
    },

    downvote() {
      this.$set(this.exercise, "vote", -1);
      axios.post(`/exercises/${this.exercise.id}/downvote`);
    },

    clearVote() {
      this.$set(this.exercise, "vote", null);
      axios.post(`/exercises/${this.exercise.id}/clear-vote`);
    },
    downloadExercise: function () {
      //this.workout.exercises.forEach((exercise) => {
        fetch(this.exercise.long_video_url)
          .then((resp) => resp.blob())
          .then(async (blob) => {
            const reader = new FileReader();

            reader.readAsDataURL(blob);

            reader.onloadend = async () => {
              const filename = getUrlFilename(this.exercise.long_video_url);

              try {
                await Filesystem.readFile({
                  path: `long_videos/${filename}`,
                  directory: FilesystemDirectory.External,
                  encoding: FilesystemEncoding.UTF8,
                  recursive: true,
                });
              } catch (ex) {
                var base64data = reader.result;

                const result = await Filesystem.writeFile({
                  path: `long_videos/${filename}`,
                  data: base64data,
                  directory: FilesystemDirectory.External,
                  encoding: FilesystemEncoding.UTF8,
                  recursive: true,
                });

                return result;
              }
            };
          });
      //});
    },
  },
};
</script>

<style >
.video-height {
  height: 200px;
}

@media (min-height: 812px) {
  .video-height {
    height: 220px;
    top: 4%;
    padding-left: 0.8rem !important;
    padding-right: 0.8rem !important;
  }
}
</style>

